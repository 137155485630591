<template>
  <div class="app-container">
    <div style="width: 1200px;">
      <img
        v-if="this.$language === 'cn'"
        style="width: 100%; height: auto;"
        src="../../assets/images/linkus/contact_us_cn.png"
      >
      <img
        v-else
        style="width: 100%; height: auto;"
        src="../../assets/images/linkus/contact_us_en.png"
      >
    </div>
    <div class="our_prent" style="margin-top: 20px;">
      <span>{{ $t('m.navbar.link_us') }}</span>
    </div>
    <div class="lx_our">
      <!--<img src="../../assets/images/linkus/lx_our_03.png">-->
    </div>
    <div class="we_bottom">
      <div v-if="this.$language === 'cn'" class="we_left">
        <table class="we_table">
          <tbody>
            <tr>
              <td><span>中国—中东欧国家技术转移中心</span></td>
            </tr>
            <tr>
              <td><span>地 址：南京市龙蟠路175号</span></td>
            </tr>
            <tr>
              <td><span>邮 编：210042</span></td>
            </tr>
            <tr>
              <td><span>电 话：+86-25-85485886</span></td>
            </tr>
            <tr>
              <td><span>邮 箱：ccttc@jittc.org</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="we_left">
        <table class="we_table">
          <tbody>
            <tr>
              <td><span>China-CEEC Technology Transfer Centre</span></td>
            </tr>
            <tr>
              <td><span>Address: 175 Longpan Rd, Nanjing, Jiangsu, P.R.China</span></td>
            </tr>
            <tr>
              <td><span>Postal code: 210042</span></td>
            </tr>
            <tr>
              <td><span>Tel: +86-25-85485886</span></td>
            </tr>
            <tr>
              <td><span>E-mail: ccttc@jittc.org</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="we_right">
        <img src="../../assets/images/linkus/us_pic_07.png">
      </div>
    </div>
    <div style="clear: both;" />
  </div>
</template>

<script>
//
export default {
  name: 'LinkUs',
  data() {
    return {
      //
    }
  }
}
</script>

<style lang="scss" scoped>
  .lx_our {
    width: 145px;
    margin: 75px auto 0;
  }
  .we_bottom {
    margin: 50px auto 0;
    width: 1000px;
    .we_left {
      float: left;
      width: 485px;
      /*background-color: red;*/
      .we_title {
        display: block;
        margin-top: 10px;
        font-size: 23px;
        color: #000;
      }
    }
    .we_right {
      width: 495px;
      height: 270px;
      float: left;
      margin-left: 15px;
    }
  }
  .we_table {
    margin-top: 30px;
    width: 687px;
    line-height: 35px;
    font-size: 14px;
    color: #333;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    background-color: transparent;
    tr {
      white-space: nowrap;
    }
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  td, th {
    padding: 0;
  }
  .we_table td:nth-child(1) {
    width: 330px;
  }
</style>
